<template>
    <div>
        <balance-transfer-account-select-card
            v-for="(instantBalanceTransferOption, index) in instantBalanceTransferOptions"
            :key="instantBalanceTransferOption.methodfiAccountId"
            :instant-balance-transfer-option="instantBalanceTransferOption"
            :index="index"
            @accountSelected="handleOnBalanceTransferAccountSelected"
        />
    </div>
</template>

<script>
    import format from '@/mixins/format'
    import BalanceTransferAccountSelectCard from '@/components/BalanceTransferAccountSelectCard.vue'

    export default {
        name: 'BalanceTransferInstantOptions',
        components: { BalanceTransferAccountSelectCard },
        mixins: [format],
        props: {
            instantBalanceTransferOptions: {
                type: Array,
                required: true,
            },
        },
        data: function () {
            return {
                instantBalanceTransferSelectionId: -1,
            }
        },
        watch: {
            instantBalanceTransferSelectionId: function (newValue) {
                this.$emit('selectOption', newValue)
            },
        },
        methods: {
            handleOnBalanceTransferAccountSelected: function (selectedId) {
                this.instantBalanceTransferSelectionId = selectedId
            },
        },
    }
</script>

<style lang="scss" scoped></style>
