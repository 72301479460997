// enums cannot be used in templates so they are only for
// this file for now.
// TODO: import https://github.com/davestewart/vue-enums
export enum RouteNames {
    SPLASH = 'Splash',
    LOGIN = 'Login',
    ACTIVITY = 'Activity',
    STATEMENTS = 'Statements',
    DOCUMENTS = 'Documents',
    CARD = 'Card',
    LOGOUT = 'Logout',
    REWARDS = 'Rewards',
    PUSH_NOTIFICATION_PERMISSION = 'PushNotificationPermission',
    PAY_IT_FORWARD = 'PayItForward',
    PAY_IT_FORWARD_CONTACT_LIST = 'PayItForwardContactList',
    PAY_IT_FORWARD_CONTACT_LIST_REMIND = 'PayItForwardContactListRemind',
    PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT = 'pifSignOnBonusAgent',
    PAY_IT_FORWARD_SEND_TIP = 'PayItForwardSendTip',
    CASH_OUT_BY_ID = 'CashOutById',
    PASSWORD_LOGIN = 'PasswordLogin',
    SET_PASSWORD = 'SetPassword',
    BALANCE_TRANSFER = 'BalanceTransfer',
    BALANCE_TRANSFER_PAYMENT_OPTIONS = 'BalanceTransferPaymentOptions',
    RECURRING_BALANCE_TRANSFER_SETTINGS = 'RecurringBalanceTransferSettings',
    EDIT_RECURRING_BALANCE_TRANSFER_AMOUNT = 'EditRecurringBalanceTransferAmount',
    CASH_OUT = 'CashOut',
    REPLACE_CARD = 'ReplaceCard',
    NOTIFICATION_SETTING = 'NotificationSetting',
    BALANCE_TRANSFER_BY_ID = 'BalanceTransferById',
    PERSONAL_DETAILS = 'PersonalDetails',
    RECURRING_BT_SELECT_CARDS = 'RecurringBtSelectCards',
    RECURRING_BT_TRANSFER_AMOUNT = 'RecurringBtTransferAmount',
    RECURRING_BT_REQUESTED = 'RecurringBtRequested',
    RECURRING_BT_INFORMATION = 'RecurringBtInformation',
    PROMO_SETUP_AUTOPAY_CREDIT = 'PromoSetupAutoPayCredit',
    PROMO_AMAZON_PRIME = 'PromoAmazonPrime',
    PROMO_AMAZON_TEN_DOLLAR_CREDIT = 'PromoAmazonTenDollarCredit',
    PROMO_AMAZON_TWENTY_FIVE_DOLLAR_CREDIT = 'PromoAmazonTwentyFiveDollarCredit',
    PROMO_TAX_PAYMENT = 'PromoTaxPayment',
    POINT_LANDING = 'PointLanding',
    SETUP_AUTOPAY = 'SetupAutoPay',
    AUTO_PAY = 'AutoPay',
    MAKE_PAYMENT = 'MakePayment',
    WIRE_TRANSFER_INSTRUCTIONS = 'WireTransferInstructions',
    PAYMENT_SUCCESS_CONFIRMATION = 'paymentSuccessConfirmation',
    AUTO_PAY_SUCCESS_CONFIRMATION = 'autoPaySuccessConfirmation',
    ADD_DEBIT_CARD = 'AddDebitCard',
    PAY = 'Pay',
    ENROLL_IN_AUTOPAY = 'EnrollInAutoPay',
    REINSTATE_CREDIT_LIMIT = 'ReinstateCreditLimit',
    AUTO_LOAN_REFINANCE = 'AutoLoanRefinance',
    DEPOSIT_CRYPTO = 'DepositCrypto',
    REPORT_LOST_OR_STOLEN = 'ReportLostOrStolen',
    CLOSE_ACCOUNT = 'CloseAccount',
    CLOSE_ACCOUNT_PERMANENT = 'CloseAccountPermanent',
    CLOSE_ACCOUNT_KEEP_CARD = 'CloseAccountKeepCard',
    CLOSE_ACCOUNT_MOFO_UPSELL = 'CloseAccountMofoUpsell',
    CLOSE_ACCOUNT_PAYOFF = 'CloseAccountPayoff',
    CLOSE_ACCOUNT_REFINANCE = 'CloseAccountRefinance',
    CLOSE_ACCOUNT_REFINANCE_ESTIMATE = 'CloseAccountRefinanceEstimate',
    CLOSE_ACCOUNT_REFINANCE_DOCUSIGN = 'CloseAccountRefinanceDocusign',
    PAYOFF_QUOTE = 'PayoffQuote',
    EMAIL_PAYOFF_QUOTE = 'EmailPayoffQuote',
    CLOSE_ACCOUNT_WIRE = 'CloseAccountWire',
    RETENTION_HOME_REATTACH = 'RetentionHomeReAttach',
    CARD_ARRIVAL_CHECK = 'CardArrivalCheck',
    SECURE_WITH_LOCATION = 'SecureWithLocation',
    FAST_FUNDING = 'FastFunding',
    CARD_ACTIVATION = 'CardActivation',
    PRIOR_LIEN_RELEASE_INFO = 'PriorLienReleaseInfo',
    CASH_OUT_UPFRONT_V2_ACTIVATION = 'CashOutUpfrontV2Activation',
    MAILER_CODE_ACTIVATION = 'MailerCodeActivation',
    BALANCE_TRANSFER_DURING_ONBOARDING = 'BalanceTransferDuringOnboarding',
    AUTHORIZED_CARDHOLDERS = 'AuthorizedCardholders',
    ADD_REVIEW = 'AddReview',
    SUPPORT_LANDING = 'Support',
    SUPPORT_ARTICLE = 'SupportArticle',
    CALL_BOOKING = 'CallBooking',
    SECURITY_CONFIRMATION = 'SecurityConfirmation',
    SINGLE_SCHEDULED_PAYMENT = 'singleScheduledPayment',
    SINGLE_USE_TOKEN_VERIFICATION = 'SingleUseTokenVerification',
    CASH_OUT_FULL_DRAW = 'CashOutFullDraw',
    CASH_OUT_UP_FRONT_BT_INSTEAD = 'CashOutUpFrontBalanceTransferInstead',
    CASH_OUT_REQUEST = 'CashOutRequest',
    CASH_OUT_PAYMENT_OPTIONS = 'CashOutPaymentOptions',
    CASH_OUT_PAYMENT_OPTIONS_FULL_DRAW = 'CashOutPaymentOptionsFullDraw',
    CASH_OUT_METHOD = 'CashOutMethod',
    CASH_OUT_BANK_STATEMENT = 'CashOutBankStatement',
    CASH_OUT_DETAILS_CONFIRMATION = 'CashOutDetailsConfirmation',
    CASH_OUT_SUCCESS_CONFIRMATION = 'CashOutSuccessConfirmation',
    CASH_OUT_HISTORY = 'CashOutHistory',
    CREDIT_SCORE_MONITORING = 'CreditScoreMonitoring',
    BALANCE_TRANSFER_REQUEST = 'BalanceTransferRequest',
    BALANCE_TRANSFER_DETAILS_CONFIRMATION = 'BalanceTransferDetailsConfirmation',
    BALANCE_TRANSFER_COMPLETE = 'BalanceTransferComplete',
    TRANSACTION_BY_ID = 'TransactionById',
    DQ_INTERSTITIAL = 'DqInterstitial',
    GET_BACK_ON_TRACK = 'GetBackOnTrack',
    GET_HELP_PAYMENT_OPTIONS = 'GetHelpPaymentOptions',
    GET_HELP_FEEDBACK = 'GetHelpFeedBack',
    AUTOPAY_SURVEY = 'AutoPaySurvey',
    AUTOPAY_ENROLLEE_FEEDBACK_SURVEY = 'AutoPayEnroleeFeedbackSurvey',
    TEST_MODES = 'TestModes',
    SIMPLE_INTEREST_CONVERSION_INTERSTITIAL = 'simpleInterestConversionInterstitial',
    ACCEPT_HCM_REMEDIATION = 'AcceptHcmRemediation',
    DECLINE_HCM_REMEDIATION = 'DeclineHcmRemediation',
    ACCEPT_TERMS_OF_SERVICE = 'AcceptTermsOfService',
    FIXED_PAYMENT_UPGRADE_INELIGIBLE = 'FixedPaymentUpgradeIneligible',
    FIXED_PAYMENT_UPGRADE_OFFER = 'FixedPaymentUpgradeOffer', // SimpleInterestLoan conversion
    ACCEPT_FIXED_PAYMENT_UPGRADE_OFFER = 'AcceptFixedPaymentUpgradeOffer', // SimpleInterestLoan conversion
    DECLINE_FIXED_PAYMENT_UPGRADE_OFFER = 'DeclineFixedPaymentUpgradeOffer', // SimpleInterestLoan conversion
    PURCHASING_SURVEY = 'PurchasingSurvey',
    REQUEST_FULL_CREDIT_LIMIT = 'RequestFullCreditLimit',
    REFI_SURVEY = 'RefiSurvey',
    ADC_OVERRIDE = 'AdcOverride',
    ADVANCE_TRANSACTION_NOTIFICATION = 'ADVANCE_TRANSACTION_NOTIFICATION',
    CREATOR_CHALLENGE = 'CreatorChallenge',
    PRIME_V3_POST_ACTIVATION = 'PrimeV3PostActivation',
    APR_REDUCTION = 'AprReduction',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION = 'UniformResidentialLoanApplication',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_MARITAL_STATUS = 'UniformResidentialLoanApplicationMaritalStatus',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_ADDRESS_HISTORY = 'UniformResidentialLoanApplicationAddressHistory',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_DEPENDENTS = 'UniformResidentialLoanApplicationDependents',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_PROPERTIES = 'UniformResidentialLoanApplicationProperties',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CURRENT_EMPLOYMENT = 'UniformResidentialLoanApplicationCurrentEmployment',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CURRENT_INCOME = 'UniformResidentialLoanApplicationCurrentIncome',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_PAST_EMPLOYMENT = 'UniformResidentialLoanApplicationPastEmployment',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_OTHER_INCOME = 'UniformResidentialLoanApplicationOtherIncome',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_OTHER_ASSETS = 'UniformResidentialLoanApplicationOtherAssets',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_LIABILITIES = 'UniformResidentialLoanApplicationLiabilities',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_DECLARATION_QUESTIONS = 'UniformResidentialLoanApplicationDeclarationQuestions',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_HMDA = 'UniformResidentialLoanApplicationHmda',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CONFIRMATION = 'UniformResidentialLoanApplicationConfirmation',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_BROKERAGE_AGREEMENT = 'UniformResidentialLoanApplicationBrokerageAgreement',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_SUBMITTED = 'UniformResidentialLoanApplicationSubmitted',
    MORTGAGE_PAYMENT_REWARDS = 'MortgagePaymentRewards',
    MORTGAGE_CASHBACK_V2_Landing = 'MortgageCashbackV2Landing',
    FIXED_TERM_CASH_OUT = 'FixedTermCashOut',
    BECOME_AMBASSADOR = 'BecomeAmbassador',
    OPEN_MOFO_OFFER = 'OpenMofoOffer',
}

export enum RoutePaths {
    SPLASH = '/splash',
    ROOT = '/',
    LOGIN = '/login',
    ACTIVITY = '/activity',
    STATEMENTS = '/statements',
    DOCUMENTS = '/documents',
    CARD = '/card',
    REWARDS = '/rewards',
    PUSH_NOTIFICATION_PERMISSION = '/pushNotificationPermission',
    NOTIFICATION_SETTING = '/notificationSetting',
    LOGOUT = '/logoutAvenCustomer',
    PAY_IT_FORWARD = '/payItForward',
    PAY_IT_FORWARD_CONTACT_LIST = '/payItForwardContactList',
    PAY_IT_FORWARD_CONTACT_LIST_REMIND = '/love/remind/:base64PhoneNumberToRemind',
    PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT = '/payItForwardAgent',
    PAY_IT_FORWARD_SEND_TIP = '/payItForwardSendTip',
    CASH_OUT_BY_ID = '/cashOut/:id',
    PASSWORD_LOGIN = '/passwordLogin',
    SET_PASSWORD = '/setPassword',
    RECURRING_BALANCE_TRANSFER_SETTINGS = '/recurringBalanceTransferSettings',
    EDIT_RECURRING_BALANCE_TRANSFER_AMOUNT = '/editRecurringBalanceTransferAmount',
    CASH_OUT = '/cashOut',
    REPLACE_CARD = '/replaceCard',
    TRANSACTION_BY_ID = '/transactionById/:transactionId',
    PERSONAL_DETAILS = '/personalDetails',
    BALANCE_TRANSFER = '/balanceTransfer',
    BALANCE_TRANSFER_PAYMENT_OPTIONS = '/balanceTransferPaymentOptions',
    BALANCE_TRANSFER_BY_ID = '/balanceTransfer/:id',
    RECURRING_BT_SELECT_CARDS = '/balanceTransfer/recurring/selectCards',
    RECURRING_BT_TRANSFER_AMOUNT = '/balanceTransfer/recurring/transferAmount/:token',
    RECURRING_BT_REQUESTED = '/balanceTransfer/recurring/transferRequested',
    RECURRING_BT_INFORMATION = '/balanceTransfer/recurring/information',
    PROMO_SETUP_AUTOPAY_CREDIT = '/promo/autopaycredit',
    PROMO_AMAZON_PRIME = '/promo/amazonPrime',
    PROMO_AMAZON_TEN_DOLLAR_CREDIT = '/promo/amazonTenCredit',
    PROMO_AMAZON_TWENTY_FIVE_DOLLAR_CREDIT = '/promo/amazonTwentyFiveCredit',
    PROMO_TAX_PAYMENT = '/promo/taxPayment',
    POINT_LANDING = '/pointLanding',
    SETUP_AUTOPAY = '/setupAutoPay', // shows 0.25% APR discount promotion
    AUTO_PAY = '/autoPay', // uses MakePayment.vue but only show auto pay tab. tab selection is hidden
    MAKE_PAYMENT = '/makePayment', // uses MakePayment.vue but only show auto pay tab. tab selection is hidden
    WIRE_TRANSFER_INSTRUCTIONS = '/wireTransferInstructions',
    PAYMENT_SUCCESS_CONFIRMATION = '/paymentSuccessConfirmation',
    AUTO_PAY_SUCCESS_CONFIRMATION = '/autoPaySuccessConfirmation',
    ADD_DEBIT_CARD = '/addDebitCard',
    PAY = '/pay', // Same as MAKE_PAYMENT but with a shorter URL
    ENROLL_IN_AUTOPAY = '/enrollInAutoPay', // uses MakePayment.vue, ui is configured same as AUTO_PAY but showing Close button instead of Back
    REINSTATE_CREDIT_LIMIT = '/reinstateCreditLimit',
    AUTO_LOAN_REFINANCE = '/autoLoanRefinance',
    DEPOSIT_CRYPTO = '/depositCrypto',
    REPORT_LOST_OR_STOLEN = '/reportLostOrStolen',
    CLOSE_ACCOUNT = '/closeAccount', // the prompt page for close account, asking the reason of closing
    CLOSE_ACCOUNT_PERMANENT = '/closeAccount/permanent', // the notice page for close account
    CLOSE_ACCOUNT_KEEP_CARD = '/closeAccount/keepCard', // shows the option of keeping the card instead of permanent close
    CLOSE_ACCOUNT_PAYOFF = '/closeAccount/payoff', // shows the payoff quote information
    CLOSE_ACCOUNT_REFINANCE = '/closeAccount/refinance',
    CLOSE_ACCOUNT_REFINANCE_ESTIMATE = '/closeAccount/refinance/estimate',
    CLOSE_ACCOUNT_REFINANCE_DOCUSIGN = '/closeAccount/refinance/docusign',
    CLOSE_ACCOUNT_MOFO_UPSELL = '/closeAccount/mofo_upsell',
    PAYOFF_QUOTE = '/payoffQuote', // shows the payoff quote information without account closure information
    EMAIL_PAYOFF_QUOTE = '/emailPayoffQuote', // email the payoff quote
    CLOSE_ACCOUNT_WIRE = '/closeAccount/wire', // shows the wire transfer information and the CTA to block the account (i.e. put the account into pending-close)
    RETENTION_HOME_REATTACH = '/homeReAttach', // home re-attach for customers in limited mode
    CARD_ARRIVAL_CHECK = '/cardArrivalCheck',
    ADD_REVIEW = '/addReview',
    SUPPORT_LANDING = '/support',
    SUPPORT_ARTICLE = '/support/article/:id',
    CALL_BOOKING = '/callBooking',
    SECURE_WITH_LOCATION = '/secureWithLocation',
    FAST_FUNDING = '/fastFunding',
    CARD_ACTIVATION = '/cardActivation',
    PRIOR_LIEN_RELEASE_INFO = '/priorLienReleaseInfo',
    CASH_OUT_UPFRONT_V2_ACTIVATION = '/cashOutUpfrontV2Activation',
    MAILER_CODE_ACTIVATION = '/mailerCodeActivation',
    BALANCE_TRANSFER_DURING_ONBOARDING = '/balanceTransferDuringOnboarding',
    AUTHORIZED_CARDHOLDERS = '/authorizedCardholders',
    SECURITY_CONFIRMATION = '/securityConfirmation',
    SINGLE_SCHEDULED_PAYMENT = '/singleScheduledPayment',
    SINGLE_USE_TOKEN_VERIFICATION = '/singleUseTokenVerification',
    CASH_OUT_FULL_DRAW = '/cashOutFullDraw',
    CASH_OUT_UP_FRONT_BT_INSTEAD = '/cashOutUpFrontBtInstead',
    CASH_OUT_REQUEST = '/cashOutRequest',
    CASH_OUT_PAYMENT_OPTIONS = '/cashOutPaymentOptions',
    CASH_OUT_PAYMENT_OPTIONS_FULL_DRAW = '/cashOutPaymentOptions/fullDraw',
    CASH_OUT_METHOD = '/cashOutMethod',
    CASH_OUT_BANK_STATEMENT = '/cashOutBankStatement',
    CASH_OUT_DETAILS_CONFIRMATION = '/cashOutDetailsConfirmation',
    CASH_OUT_SUCCESS_CONFIRMATION = '/cashOutSuccessConfirmation',
    CASH_OUT_HISTORY = '/cashOutHistory',
    CREDIT_SCORE_MONITORING = '/creditScoreMonitoring',
    BALANCE_TRANSFER_REQUEST = '/balanceTransferRequest',
    BALANCE_TRANSFER_DETAILS_CONFIRMATION = '/balanceTransferDetailsConfirmation',
    BALANCE_TRANSFER_COMPLETE = '/balanceTransferComplete',
    DQ_INTERSTITIAL = '/dqInterstitial',
    GET_BACK_ON_TRACK = '/getBackOnTrack',
    GET_HELP_PAYMENT_OPTIONS = '/getHelpPaymentOptions',
    GET_HELP_FEEDBACK = '/getHelpFeedback',
    AUTOPAY_SURVEY = '/autopaySurvey',
    AUTOPAY_ENROLLEE_FEEDBACK_SURVEY = '/autopayEnrolleeFeedbackSurvey',
    TEST_MODES = '/testModes',
    SIMPLE_INTEREST_CONVERSION_INTERSTITIAL = '/simpleInterestConversionInterstitial',
    FIXED_PAYMENT_UPGRADE_OFFER = '/fixedPaymentUpgradeOffer',
    ACCEPT_FIXED_PAYMENT_UPGRADE_OFFER = '/acceptFixedPaymentUpgradeOffer',
    DECLINE_FIXED_PAYMENT_UPGRADE_OFFER = '/declineFixedPaymentUpgradeOffer',
    FIXED_PAYMENT_UPGRADE_INELIGIBLE = '/fixedPaymentUpgradeIneligible',
    ACCEPT_HCM_REMEDIATION = '/acceptHcmOffer',
    DECLINE_HCM_REMEDIATION = '/declineHcmOffer',
    ACCEPT_TERMS_OF_SERVICE = '/acceptTermsOfService',
    PURCHASING_SURVEY = '/purchasingSurvey',
    REQUEST_FULL_CREDIT_LIMIT = '/requestFullCreditLimit',
    REFI_SURVEY = '/refiSurvey',
    ADC_OVERRIDE = '/adcOverride',
    ADVANCE_TRANSACTION_NOTIFICATION = '/advanceTransactionNotification',
    CREATOR_CHALLENGE = '/creatorChallenge',
    PRIME_V3_POST_ACTIVATION = '/primeV3PostActivation',
    APR_REDUCTION = '/credit/aprReduction',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION = '/uniformResidentialLoanApplication',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_MARITAL_STATUS = '/uniformResidentialLoanApplication/maritalStatus',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_ADDRESS_HISTORY = '/uniformResidentialLoanApplication/addressHistory',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_DEPENDENTS = '/uniformResidentialLoanApplication/dependents',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_PROPERTIES = '/uniformResidentialLoanApplication/properties',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CURRENT_EMPLOYMENT = '/uniformResidentialLoanApplication/currentEmployment',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CURRENT_INCOME = '/uniformResidentialLoanApplication/currentIncome',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_PAST_EMPLOYMENT = '/uniformResidentialLoanApplication/pastEmployment',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_OTHER_INCOME = '/uniformResidentialLoanApplication/otherIncome',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_OTHER_ASSETS = '/uniformResidentialLoanApplication/otherAssets',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_LIABILITIES = '/uniformResidentialLoanApplication/liabilities',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_DECLARATION_QUESTIONS = '/uniformResidentialLoanApplication/declarationQuestions',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_HMDA = '/uniformResidentialLoanApplication/hmda',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_CONFIRMATION = '/uniformResidentialLoanApplication/confirmation',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_BROKERAGE_AGREEMENT = '/uniformResidentialLoanApplication/brokerageAgreement',
    UNIFORM_RESIDENTIAL_LOAN_APPLICATION_SUBMITTED = '/uniformResidentialLoanApplication/submitted',
    MORTGAGE_PAYMENT_REWARDS = '/mortgagePaymentRewards',
    MORTGAGE_CASHBACK_V2_Landing = '/mortgageCashbackV2Landing',
    FIXED_TERM_CASH_OUT = '/fixedTermCashOut',
    BECOME_AMBASSADOR = '/becomeAmbassador',
    OPEN_MOFO_OFFER = '/openMofoOffer',
}
