<template>
    <div
        v-if="loading"
        class="container"
    >
        <loading-indicator
            v-if="loading"
            :title="$t('global.loadingMessage.loading')"
        />
    </div>
    <div v-else>
        <product-selection-header
            :title="title"
            :subtitle="formattedDate()"
            class="mobile-header"
            data-testid="product-select-header"
        />
        <div>
            <div
                v-for="(productInfoDetail, index) in productInfoResponse.productInfoDetails"
                :key="productInfoDetail.productType"
                class="mt-2"
            >
                <div
                    class="custom-control custom-radio custom-control-tight-right h-auto"
                    :data-testid="`product-select-detail-${index}`"
                >
                    <input
                        type="radio"
                        :id="`productSelectionOption_${productInfoDetail.productType}`"
                        :name="`productSelectionOption_${productInfoDetail.productType}`"
                        class="custom-control-input"
                        @change="handleProductSelection(productInfoDetail.productType)"
                    >
                    <label
                        class="product-selection-option d-flex custom-control-label custom-control-label-tight-right p-2 bg-white"
                        :for="`productSelectionOption_${productInfoDetail.productType}`"
                    >
                        <div class="d-flex">
                            <div>
                                <p class="section-header mb-0">
                                    {{ cardTitle(productInfoDetail) }}
                                </p>
                                <h3
                                    v-if="shouldShowCurrentBalance(productInfoDetail)"
                                    class="fw-bold mb-0"
                                >
                                    {{ toFormattedUSD(productInfoDetail.currentBalance) }}
                                </h3>
                                <p
                                    v-if="shouldShowCurrentBalance(productInfoDetail)"
                                    class="mb-0"
                                >Current Balance</p>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import ProductSelectionHeader from '@/components/ProductSelectionHeader.vue'
    import { ProductInfoDetails } from '@/mixins/loginMixin'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { AvenCardProductType } from 'aven_types'
    import format from '@/mixins/format'
    import LoadingIndicator from '@/components/LoadingIndicator.vue'
    import { logger } from '@/utils/logger'

    export default {
        name: 'ProductSelection',
        components: {
            LoadingIndicator,
            ProductSelectionHeader,
        },
        props: {
            productInfoResponse: {
                type: Object,
                required: true,
            },
            onSubmit: {
                type: Function,
                required: true,
            },
        },
        mixins: [format],
        data() {
            return {
                loading: false,
                date: new Date(),
            }
        },
        computed: {
            title() {
                const hours = this.date.getHours()
                // between midnight and noon say 'Good Morning', between noon and 5pm say 'Good Afternoon', otherwise say 'Good Evening'
                if (hours < 12) {
                    return 'Good Morning'
                } else if (hours < 17) {
                    return 'Good Afternoon'
                } else {
                    return 'Good Evening'
                }
            },
        },
        methods: {
            cardTitle(productSelectionDetails: ProductInfoDetails) {
                return `${productSelectionDetails.productType} CARD (••••${productSelectionDetails.last4RedactedAccessDeviceNumber})`
            },
            formattedDate() {
                return this.date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timeZone: this.productInfoResponse.customerTimeZone,
                })
            },
            handleProductSelection: async function (productType: AvenCardProductType) {
                this.loading = true
                appSessionStorage.setItem(localStorageKey.productType, productType)
                await this.onSubmit()
                this.loading = false
            },
            shouldShowCurrentBalance: function (productInfoDetail) {
                if (productInfoDetail.currentBalance === undefined || productInfoDetail.currentBalance === null) {
                    logger.fatal(`Product selection screen should be able to show current balance, but did not receive it for ${JSON.stringify(productInfoDetail)}`)
                    return false
                }
                return true
            },
        },
    }
</script>

<style lang="scss" scoped>
    .custom-control-label-tight-right::before {
        top: calc(4.5rem / 2) !important;
        right: 1.5rem !important;
        width: 48px !important;
        height: 48px !important;
        background-image: url('../assets/right-arrow-round.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border: none;
    }

    .custom-control-label-tight-right::after {
        top: calc((85px - 48px) / 2);
        right: 1rem;
    }

    .custom-control-label-tight-right {
        padding-right: 80px;
    }

    .product-selection-option {
        border: 1px solid $gray-200;
        border-radius: 8px;
        cursor: pointer;
    }

    .product-selection-option:hover {
        box-shadow: $shadow-shallow;
    }
</style>
