<template>
    <button
        class="common-action-button"
        v-on="$listeners"
    >
        <div class="common-action-button-body">
            <div
                class="common-action-button-icon"
                :class="{ 'common-action-button-icon-invert-color': invertIconColor }"
            >
                <div
                    v-if="iconSrc"
                    class="common-action-button-icon-container"
                >
                    <img
                        :src="require(`@/assets/${iconSrc}`)"
                        :alt="text"
                    >
                </div>
            </div>
            <div class="section-header common-action-button-text text-dark fw-bold">
                <span v-html="text" />
            </div>
        </div>
    </button>
</template>

<script>
    export default {
        name: 'CommonActionButton',
        props: {
            iconSrc: {
                type: String,
                required: true,
            },
            invertIconColor: {
                type: Boolean,
                default: false,
                required: false,
            },
            text: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style scoped>
    .common-action-button {
        padding: 0;
        display: flex;
        background: none;
        border: none;
        width: 100px;
        min-height: 100px;
    }

    .common-action-button-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .common-action-button-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .common-action-button-icon-container img {
        width: 32px;
        height: 32px;
    }

    .common-action-button-icon-invert-color img {
        /* Will make the black svg to white */
        filter: invert(1);
    }

    .common-action-button-text {
        margin-top: 8px;
        width: 98px;
        text-align: center;
    }
</style>
