<template>
    <div class="custom-control custom-radio custom-control-tight-right h-auto mb-2">
        <input
            type="radio"
            :id="`instantBalanceTransferOption_${instantBalanceTransferOption.methodfiAccountId}`"
            :name="`instantBalanceTransferOption_${instantBalanceTransferOption.methodfiAccountId}`"
            class="custom-control-input"
            :value="index"
            @change="handleOnSelect"
        >
        <label
            class="liability-select-option d-flex custom-control-label pt-2 pb-2"
            :class="{
                'custom-control-label-tight-right': !instantBalanceTransferOption.isRecurringBalanceTransferActive,
                'custom-control-label-tight-right-no-icon': instantBalanceTransferOption.isRecurringBalanceTransferActive,
            }"
            :for="`instantBalanceTransferOption_${instantBalanceTransferOption.methodfiAccountId}`"
            :data-testid="`instant-balance-transfer-option-${index}`"
        >
            <div class="d-flex align-items-center ms-2 me-2">
                <img
                    :src="instantBalanceTransferOption.logoUrl"
                    :alt="instantBalanceTransferOption.financialInstitutionName"
                    class="financial-institution-logo"
                >
            </div>
            <div class="d-flex min-width-0">
                <div class="text-start min-width-0">
                    <p class="fw-bold mb-0 text-truncate">
                        {{ instantBalanceTransferOption.name }}
                    </p>
                    <div
                        class="mb-0 small text-muted"
                        v-if="instantBalanceTransferOption.isRecurringBalanceTransferActive"
                    >
                        {{ $t('components.balanceTransfer.instantSelect.nextTransferOn', { transferDate: dateOfNextTransfer }) }}
                    </div>
                    <div v-else>
                        <p
                            v-if="instantBalanceTransferOption.balanceCents !== null"
                            class="mb-0"
                        >
                            {{ toFormattedUSD(instantBalanceTransferOption.balanceCents / 100.0) }}
                        </p>
                        <p class="mb-0 small text-muted">
                            {{ instantBalanceTransferOption.lastUpdateRelativeTimeStr }}
                        </p>
                    </div>
                </div>
                <p
                    v-if="instantBalanceTransferOption.mask && instantBalanceTransferOption.mask !== '0000'"
                    class="fw-bold mb-0 account-mask"
                >({{ instantBalanceTransferOption.mask }})</p>
            </div>
        </label>
    </div>
</template>

<script>
    import { toFormattedUSD } from '@/mixins/format'

    export default {
        name: 'BalanceTransferAccountSelectCard',
        props: {
            index: {
                type: Number,
                required: true,
            },
            instantBalanceTransferOption: {
                type: Object,
                required: true,
            },
        },
        computed: {
            dateOfNextTransfer: function () {
                return new Date(this.instantBalanceTransferOption.nextRecurringBalanceTransferDate).toLocaleDateString(undefined, {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                })
            },
        },
        methods: {
            toFormattedUSD,
            handleOnSelect: function () {
                this.$emit('accountSelected', this.instantBalanceTransferOption.methodfiAccountId)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .custom-control-label.custom-control-label-tight-right::before {
        top: calc((85px - 48px) / 2);
        right: 1rem;
        width: 48px;
        height: 48px;
        background-image: url('../assets/right-arrow-round.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border: none;
    }

    .custom-control-label.custom-control-label-tight-right-no-icon::before {
        width: 0;
        height: 0;
        background-image: none;
        border: none;
    }

    .custom-control-label-tight-right::after {
        top: calc((85px - 48px) / 2);
        right: 1rem;
    }

    .custom-control-label-tight-right {
        padding-right: 80px;
    }

    .financial-institution-logo {
        object-fit: contain;
        height: 48px;
        width: 48px;
    }

    .min-width-0 {
        min-width: 0;
    }

    .account-mask {
        padding-left: 4px;
    }

    .liability-select-option {
        border: 1px solid $gray-200;
        border-radius: 8px;
        cursor: pointer;
    }
</style>
