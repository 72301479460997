import { defineStore } from 'pinia'
import { PaymentSource } from '@/store/paymentStore.types'
import { useGlobalUiStore } from '@/store/globalUiStore'
import { aciGenerateFundingTokenBankAccount, aciSearchFundingSources, avenAciDeleteFundingSource, avenAutopayDetails, avenAutopayOptIn, avenAutopayOptOut } from '@/services/api'
import { AciAccountSubType } from '@/data/constants'
import { useCustomerInfoStore } from '@/store/customerInfoStore'
import { logger } from '@/utils/logger'
import assert from 'assert'
import { useOverviewStore } from '@/store/overviewStore'
import { AUTO_PAY_DISABLED, AutopayCategory, AutoPayDetails } from '@/store/overviewStore.types'
import { NETWORK_ERROR } from '@/store/storeUtils'
import { openReplay } from '@/utils/openReplay'

export const usePaymentsStore = () => {
    const paymentsStore = defineStore('payments', {
        state: () => {
            return {
                paymentSources: [] as PaymentSource[],
                selectedPaymentSource: Object.create(null) as PaymentSource,
            }
        },
        getters: {},
        actions: {
            async fetchPaymentSources() {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await aciSearchFundingSources()
                    if (data.success) {
                        this.paymentSources = data.payload
                    } else {
                        // TODO: add error state
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async addPaymentSource(payload: { bankAccountType: AciAccountSubType; routingNumber: string; bankAccountNumber: string; accountNickName: string }) {
                const globalUiStore = useGlobalUiStore()
                const customerInfoStore = useCustomerInfoStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await aciGenerateFundingTokenBankAccount(
                        payload.bankAccountType,
                        payload.routingNumber,
                        payload.bankAccountNumber,
                        customerInfoStore.fullName,
                        customerInfoStore.state,
                        customerInfoStore.postalCode,
                        payload.accountNickName
                    )

                    if (data.success) {
                        logger.info(`Successfully added payment source ${JSON.stringify(data)}`)
                        this.paymentSources = [...this.paymentSources, data.payload]
                    } else {
                        logger.info(`Error adding payment source ${JSON.stringify(data)}`)
                        globalUiStore.error = data.error
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async deletePaymentSource(fundingTokenId: string) {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const fundingSource = this.paymentSources.find((s) => s.token === fundingTokenId)
                    assert(fundingSource, `trying to delete payment, cannot find source with token ${fundingTokenId}`)
                    const { data } = await avenAciDeleteFundingSource(fundingTokenId)
                    if (data.success) {
                        logger.info(`Successfully removed payment source ${JSON.stringify(data)}`)
                        const overviewStore = useOverviewStore()
                        if (overviewStore.autoPaySetting.isEnabled && fundingTokenId === overviewStore.autoPaySetting.autoPayDetails?.aciFundingTokenId) {
                            overviewStore.autoPaySetting = AUTO_PAY_DISABLED
                        }
                        this.paymentSources = this.paymentSources.filter((s) => s.token !== fundingTokenId)
                    } else {
                        logger.info(`Error removing payment source ${data.error}`)
                        globalUiStore.error = data.error
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async refreshAutoPayDetails() {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await avenAutopayDetails()
                    if (data.success) {
                        useOverviewStore().autoPaySetting = data.payload
                    } else {
                        globalUiStore.error = data.error
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async autoPayOptIn(payload: AutoPayDetails) {
                // if category is custom_amount then payload.customAmount must exist
                if (payload.autoPayCategory === AutopayCategory.CUSTOM_AMOUNT) {
                    assert(payload.customAmount, 'AutoPay category is CUSTOM_AMOUNT but auto pay amount is null/undefined')
                }
                const overviewStore = useOverviewStore()
                const autoPayDetails = overviewStore.autoPaySetting.autoPayDetails
                const autoPayPaymentSourceChanged = autoPayDetails?.aciFundingTokenId !== payload.aciFundingTokenId
                const autoPayCategoryChanged = autoPayDetails?.autoPayCategory !== payload.autoPayCategory
                const autoPayCustomAmountChanged = autoPayDetails?.autoPayCategory === AutopayCategory.CUSTOM_AMOUNT && autoPayDetails.customAmount !== payload.customAmount
                logger.info(
                    `AutoPay setting update, autoPayPaymentSourceChanged: ${autoPayPaymentSourceChanged}, autoPayCategoryChanged: ${autoPayCategoryChanged}, autoPayCustomAmountChanged: ${autoPayCustomAmountChanged},  current: ${JSON.stringify(
                        overviewStore.autoPaySetting
                    )}, next: ${JSON.stringify(payload)}`
                )

                if (!autoPayPaymentSourceChanged && !autoPayCategoryChanged && !autoPayCustomAmountChanged) {
                    return
                }
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const paymentSource = this.paymentSources.find((payment) => payment.token === payload.aciFundingTokenId)
                    assert(paymentSource, `autoPayOptIn, Cannot find payment source ${payload.aciFundingTokenId} in state.paymentSources`)
                    const { data } = await avenAutopayOptIn(paymentSource.accountType, paymentSource.token, payload.autoPayCategory, payload.customAmount ? `${payload.customAmount}` : '')
                    if (data.success) {
                        overviewStore.autoPaySetting = {
                            isEnabled: true,
                            autoPayDetails: payload,
                        }
                    } else {
                        globalUiStore.error = data.error
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
            async autoPayOptOut() {
                const globalUiStore = useGlobalUiStore()
                try {
                    globalUiStore.$patch({
                        loading: true,
                        error: '',
                    })
                    const { data } = await avenAutopayOptOut()
                    if (data.success) {
                        useOverviewStore().autoPaySetting = AUTO_PAY_DISABLED
                    } else {
                        globalUiStore.error = data.error
                    }
                } catch (e) {
                    globalUiStore.error = NETWORK_ERROR
                    throw e
                } finally {
                    globalUiStore.loading = false
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(paymentsStore)
    return paymentsStore
}
