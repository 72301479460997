import { appSessionStorage, localStorageKey } from '@/utils/storage'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import inspect from 'util-inspect'
import { logger } from '@/utils/logger'
import { useSessionStore } from '@/store/sessionStore'

const httpClient: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
    timeout: 60000,
})

const myHttpClient: AxiosInstance = axios.create({
    baseURL: process.env.AVEN_MY_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
    timeout: 60000,
})
const loggerHttpClient: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_LOGGER_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
    },
    responseType: 'text',
    timeout: 60000,
})

const authInterceptor = (request: AxiosRequestConfig) => {
    /** add auth token */
    const accessToken = appSessionStorage.getItem(localStorageKey.jwtToken)
    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }

    // Custom header for SessionJWT Authentication strategy
    const sessionAccessToken = useSessionStore().sessionAccessJWT
    if (sessionAccessToken) {
        request.headers.SessionAuthorization = `Bearer ${sessionAccessToken}`
    }

    return request
}

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor)

httpClient.interceptors.response.use((response) => {
    return response
})

const RETRY_INTERVAL_MSEC = 6000

const runWithRetryLogic = async <T>(requestFunc: () => Promise<T>, maxRetryCount: number, customRetryInterval?: number, errHandler?: (exception) => {}): Promise<T> =>
    new Promise((resolve, reject) => {
        const retryInterval = customRetryInterval || RETRY_INTERVAL_MSEC

        let attemptNumber = 0
        const retryAndLog = async () => {
            try {
                const ret = await requestFunc()
                return resolve(ret)
            } catch (error) {
                const allowRetry = error.response?.status === undefined || error.response?.status === 0 || error.code === 'ECONNABORTED' || error.code === 'ECONNRESET'
                if (!allowRetry) {
                    return reject(error)
                }

                logger.log(`Retry attempt ${attemptNumber}/${maxRetryCount} failed due to error: ${inspect(error)}`)
                attemptNumber++
                if (attemptNumber > maxRetryCount) {
                    return reject(new Error(`Max retry limit of ${maxRetryCount} exceeded with error: ${inspect(error)}`))
                }
                logger.log(`Next retry attempt in ${retryInterval} ms`)

                try {
                    if (errHandler) {
                        logger.log(`Calling error handler`)
                        await errHandler(error)
                    }
                } catch (e) {
                    logger.error(`Error handler failed with exception: ${e}`)
                }

                setTimeout(retryAndLog, retryInterval)
            }
        }
        retryAndLog()
    })

export { httpClient, myHttpClient, loggerHttpClient, runWithRetryLogic }

window.logEvent = function (eventName, properties) {
    const sessionId = useSessionStore().sessionId
    if (!sessionId) {
        logger.error('Cannot log event without sessionId')
        return
    }

    const body = {
        eventName,
        properties: { ...properties, currentPath: window.location.pathname, previousPath: window.previousPath },
        sessionId,
    }

    runWithRetryLogic(async () => await httpClient.post('/aven_app/ana/evnt', body), 2).catch((e) => {
        logger.error(`analytics event failed! Note that this does not impact the users experience. Error was ${e}`)
    })
}
